(() => {
    const FORM_HAS_ERROR_CLASS = "has-error";
    const SHOWN_CLASS = "shown";
    const INPUT_ERROR_CLASS = "has-error";

    document.addEventListener("DOMContentLoaded", () => {
        const form = document.querySelector("#contact-form");

        if (form) {
            const nameInput = form.querySelector("#name");
            const emailInput = form.querySelector("#email");
            const messageInput = form.querySelector("#message");
            const submitButton = form.querySelector("#submit");

            submitButton.setAttribute("disabled", 1);

            const nameError = form.querySelector(
                '[data-js="name-error-message"]'
            );
            const emailError = form.querySelector(
                '[data-js="email-error-message"]'
            );
            const messageError = form.querySelector(
                '[data-js="message-error-message"]'
            );

            function nameIsValid() {
                return nameInput.value.trim().length > 0;
            }

            function emailIsValid() {
                return (
                    emailInput.value.trim().length > 0 &&
                    /.+@.+\..+/g.test(emailInput.value)
                );
            }

            function messageIsValid() {
                return messageInput.value.trim().length > 29;
            }

            function updateNameMessages() {
                const isNotValid = !nameIsValid();
                nameInput.classList.toggle(INPUT_ERROR_CLASS, isNotValid);
                nameError.classList.toggle(SHOWN_CLASS, isNotValid);
            }

            function updateEmailMessages() {
                const messageItem = emailError.querySelector(
                    ".input-group-error__message"
                );

                if (emailInput.value.trim().length === 0) {
                    messageItem.textContent = "Required field";
                    emailError.classList.add(SHOWN_CLASS);
                    emailInput.classList.add(INPUT_ERROR_CLASS);
                    return;
                }

                if (!/.+@.+\..+/g.test(emailInput.value)) {
                    messageItem.textContent = "Please enter a valid email.";
                    emailError.classList.add(SHOWN_CLASS);
                    emailInput.classList.add(INPUT_ERROR_CLASS);
                    return;
                }

                emailError.classList.remove(SHOWN_CLASS);
                emailInput.classList.remove(INPUT_ERROR_CLASS);
            }

            function updateMessageMessages() {
                const notValid = !messageIsValid();
                messageInput.classList.toggle(INPUT_ERROR_CLASS, notValid);
                messageError.classList.toggle(SHOWN_CLASS, notValid);
            }

            function addListenersToInput(input, cb) {
                function _cb() {
                    cb();
                    updateFormStatus(false);
                }
                input.addEventListener("blur", _cb);
                input.addEventListener("input", _cb);
            }

            function isFormValid() {
                return nameIsValid() && emailIsValid() && messageIsValid();
            }

            function updateFormStatus(silent = true) {
                if (!isFormValid()) {
                    submitButton.setAttribute("disabled", 1);
                    !silent && form.classList.add(FORM_HAS_ERROR_CLASS);
                } else {
                    submitButton.removeAttribute("disabled");
                    form.classList.remove(FORM_HAS_ERROR_CLASS);
                }
            }

            addListenersToInput(nameInput, updateNameMessages);
            addListenersToInput(emailInput, updateEmailMessages);
            addListenersToInput(messageInput, updateMessageMessages);
            updateFormStatus();

            submitButton.addEventListener("click", (ev) => {
                ev.preventDefault();

                fetch("/api/contact", {
                    method: "POST",
                    body: JSON.stringify({
                        name: nameInput.value.trim(),
                        email: emailInput.value.trim(),
                        message: messageInput.value.trim(),
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((jsonResponse) => {
                        if (
                            jsonResponse.status === 200 &&
                            jsonResponse.message === "Message sent"
                        ) {
                            form.classList.add("sent");
                            nameInput.setAttribute("disabled", 1);
                            emailInput.setAttribute("disabled", 1);
                            messageInput.setAttribute("disabled", 1);
                            submitButton.textContent = "Message sent";
                            submitButton.setAttribute("disabled", 1);
                        } else {
                            alert("Sorry, a problem has occured!");
                        }
                    });
            });
        }
    });
})();
