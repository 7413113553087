(() => {
    document.addEventListener('DOMContentLoaded', () => {
        const preferReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        if (!preferReducedMotion) {
            const scrollerElements = [...document.querySelectorAll('.scroller')];

            scrollerElements.forEach(scrollerEl => {
                scrollerEl.setAttribute('data-animate', true);


                const innerContainer = scrollerEl.querySelector('.scroller__inner');
                const children = [...innerContainer.children];
                children.forEach(child => {
                    const newChild = child.cloneNode(true);
                    newChild.setAttribute('aria-hidden', true);
                    innerContainer.appendChild(newChild);
                });
            });
        }


    });
})();

