(() => {
    const canvas = document.querySelector('#bg-canvas');
    const balls = [];
    let comet = null;
    const context = canvas.getContext('2d');

    const mousePosition = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    };

    const __randomBetween = (x, y, offset = 0) => Math.random() * (y - x - offset) + x + offset / 2;

    class Comet {
        #markedAsReset;

        constructor() {
            this.setRandomStartPosition();
            this.dx = 2;
            this.dy = -2;
            this.size = 30;
            this.color = 'rgb(230 230 230 / 0.9)';
            this.image = new Image(this.size, this.size);
            this.image.src = '/assets/img/comet.png';
        }

        setRandomStartPosition() {
            this.x = __randomBetween(-100, window.innerWidth / 3);
            this.y = __randomBetween(window.innerHeight + 120, window.innerHeight);
            this.#markedAsReset = false;
        }

        update() {
            this.x += this.dx;
            this.y += this.dy;
            if (this.x > window.innerWidth + this.size || this.y < -this.size) {
                if (!this.#markedAsReset) {
                    setTimeout(() => {
                        this.setRandomStartPosition();
                    }, __randomBetween(2000, 5000));
                    this.#markedAsReset = true;
                }
                return;
            }

            context.drawImage(this.image, this.x, this.y, this.size, this.size);
        }

        reset() {
            return this.setRandomStartPosition();
        }
    }

    class Ball {
        constructor({ radius, color }) {
            this.radius = radius || Math.round(__randomBetween(1, 3));
            this.color = color || 'rgb(250 250 250 / .15)';
            this.defaultColor = 'rgb(250 250 250 / .15)';
            this.x = __randomBetween(this.radius, window.innerWidth - this.radius);
            this.y = __randomBetween(this.radius, window.innerHeight - this.radius);
            this.dx = __randomBetween(-0.05, 0.05);
            this.dy = __randomBetween(-0.05, 0.05);
        }

        update() {
            if (this.x + this.radius > window.innerWidth || this.x - this.radius < 0) {
                this.dx *= -1;
            }
            if (this.y + this.radius > window.innerHeight || this.y - this.radius < 0) {
                this.dy *= -1;
            }

            this.x += this.dx;
            this.y += this.dy;
            context.fillStyle = this.color;
            context.beginPath();
            context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
            context.fill();
        }

    }

    function __animate() {
        context.fillStyle = '#161416';
        context.save();
        context.globalAlpha = '0.8';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.restore();

        balls.forEach(i => i.update());
        comet?.update();
        requestAnimationFrame(__animate);
    }

    function updateSize() {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        mousePosition.x = window.innerWidth / 2;
        mousePosition.y = window.innerHeight / 2;
    }

    function __init() {
        updateSize();
        window.addEventListener('resize', updateSize);

        const ballsQuantity = 180;
        for (let i = 0; i < ballsQuantity; ++i) {
            balls.push(new Ball({}));
        }
        comet = new Comet();

        __animate();
    }

    __init();
})();
